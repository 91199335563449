import { useEffect, useRef, useState } from 'react'

import { getNormalizedAppName } from '@/services/functions'

import { newTracker, enableActivityTracking } from '@snowplow/browser-tracker'

const Tracked = ({ children }) => {
  const [hasTracker, setHasTracker] = useState(false)
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) return

    newTracker('sp1', process.env.SNOWPLOW_URL, {
      appId: getNormalizedAppName(),
      contexts: {
        session: true,
        browser: true
      }
    })

    enableActivityTracking({
      minimumVisitLength: 10,
      heartbeatDelay: 10
    })

    setHasTracker(true)
    didMount.current = true
  }, [])

  return hasTracker && children
}

export default Tracked
